import { useContext, useEffect, useState } from "react";
import FileUpload from "./FileUpload";
import FileView from "./FileView";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import Banner from "../Landing_page/Banner";
import { Box } from "@mui/material";

const Index = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [containsFiles, setContainsFile] = useState<any>(false);
    const [pdfList, setPdfList] = useState<any>([]);

    const file: any = [
        {
            file_id: "588a3dee-5ac7-41ec-a1ca-401568095dd9",
            user_id: "3ddf28da-ca5e-4615-ab05-50d30c888307",
            file_name: "Batch Manufacturing record",
            file_type: "audio/video",
            is_deleted: false,
            file_settings: {
                s3_url: null,
                file_format: "video",
                vector_store: "pinecone",
                vector_index: "softx-azure-index",
            },
            is_processed: true,
            created_at: "2024-10-04T02:55:44.453885+00:00",
            updated_at: null,
            file_hash: "e051eca2ee9ef8e6c875058c6d02f5d429f2b8e7d8f3dddfb7e7db2cf10891bd",
        },
    ];

    const fetchPathologyList = () => {
        Api?.getPathoNamespaces({ user_id: contextStore?.profile?.userId })?.then((res: any) => {
            if (res?.status === 200) {
                setContext({
                    ...contextStore,
                    pathalogyList: file,
                });
            }
        });
    };

    useEffect(() => {
        fetchPathologyList();
    }, []);

    return (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            {/* {banner && !sessionStorage.getItem("pathology") ? null : null} */}
            <FileView
                containsFiles={containsFiles}
                setContainsFile={setContainsFile}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                pdfList={pdfList}
                setPdfList={setPdfList}
                fetchPathologyList={fetchPathologyList}
            />
        </Box>
    );
};

export default Index;
