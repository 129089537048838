import React, { useContext, useEffect, useState } from "react";
import { Box, MenuItem, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import chatIcon from "../../components/Common/svgLogs/ChatIcon";
import HomeIcon from "../../components/Common/svgLogs/HomeIcon";
import calculator from "../../components/Common/svgLogs/calculator";
import DatabaseIcon from "../../components/Common/svgLogs/DatabaseIcon";
import HistoryIcon from "../../components/Common/svgLogs/HistoryIcon";
import { useNavigate } from "react-router-dom";
import IconButton from "../../components/Common/Components/IconButton";
import SearchMidiumIcon from "../../components/Common/svgLogs/SearchMidiumIcon";
import CompareIcon from "../../components/Common/svgLogs/CompareIcon";
import SummarizeIcon from "../../components/Common/svgLogs/SummarizeIcon";
import SettingIcon from "../../components/Common/svgLogs/SettingIcon";
import { AccessibilityNewSharp, CarCrashSharp, Close, Menu } from "@mui/icons-material";
import MuiIconButton from "@mui/material/IconButton";
import ImageIcon from "../../components/Common/svgLogs/ImageIcon";
import GraphIcon from "../../components/Common/svgLogs/VerticalBarGraphIcon";
import { Context } from "../../context/setting";
import EcgIcon from "../../components/Common/svgLogs/EcgIcon";
import BulletTrainIcon from "../../components/Common/svgLogs/BulletTrainIcon";
import ReportGenerationIcon from "../../components/Common/svgLogs/ReportGenerationIcon";
import AnamolyIcon from "../../components/Common/svgLogs/AnamolyIcon";
import foodIcon from "../../components/Common/svgLogs/foodIcon";
import AlertGenIcon from "../../components/Common/svgLogs/AlertGenIcon";
import ClaimsLoo from "../../components/Common/svgLogs/ClaimsLoo";
import CareIcon from "../../components/Common/svgLogs/CareIcon";
import MedicalSummarizer from "../../components/Common/svgLogs/MedicalSummarizer";
import { stroke } from "pdf-lib";
import FinanseIcon from "../../components/Common/svgLogs/FinanseIcon";
import SearchHighlighterIcon from "../../components/Common/svgLogs/Search_Highlighter/Search_HighlighterLogo";
import DamageDetectionIcon from "../../components/Common/svgLogs/Damage_Detection/DamageDetectionIcon";
import HRLogo from "../../components/Common/svgLogs/HR_Analysis/HRLogo";
import CyberNavbarIcon from "../../components/Common/svgLogs/CyberSecurity/cyberNavbarIcon";
import Auegon from "../../components/Common/svgLogs/Auegon";
import AiContactCenter from "../../components/Common/svgLogs/AiContactCenter";

const Index = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const theme = useTheme();
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));
    const [selected, setSelected] = useState("Home");
    const [selectedPricingCalculator, setSelectedPricingCalculator] = useState(false);
    const [menuCollapse, setMenuCollapse] = useState<boolean>(true);

    const options = [
        { title: "Home", onClick: () => navigate("/"), icon: HomeIcon, key: "Home" },
        { title: "SensorX Chat", onClick: () => navigate("/chat_start"), icon: chatIcon, key: "ChatStart" },
        {
            title: "SensorX Bullet",
            onClick: () => navigate("/bullet"),
            icon: BulletTrainIcon,
            key: "Similarity Search",
        },
        // { title: "Search", onClick: () => navigate("/search"), icon: SearchMidiumIcon },
        {
            title: "Compare Documents",
            onClick: () => navigate("/compare"),
            icon: CompareIcon,
            key: "Compare",
        },
        // { title: "Visual", onClick: () => navigate("/visual"), icon: GraphIcon, key: "Visual" },
        { title: "Summarize", onClick: () => navigate("/summarize"), icon: SummarizeIcon, key: "Summarize" },
        { title: "AI Images", onClick: () => navigate("/image"), icon: ImageIcon, key: "Image Generation" },
        { title: "Pathology report", onClick: () => navigate("/pathology"), icon: EcgIcon, key: "Pathology Report" },
        // {
        //     title: "Report generation",
        //     onClick: () => navigate("/report_generation"),
        //     icon: ReportGenerationIcon,
        //     key: "Report generation",
        // },

        {
            title: "Restaurant Bot",
            onClick: () => navigate("/restaurant_bot"),
            icon: foodIcon,
            key: "Restaurant Bot",
        },
        {
            title: "Claims",
            onClick: () => navigate("/claims"),
            icon: ClaimsLoo,
            key: "Claims",
        },
        {
            title: "Alert Generation",
            onClick: () => {
                navigate("/alert_generation");
            },
            icon: AlertGenIcon,
            key: "Alert Generation",
        },
        {
            title: "Care",
            onClick: () => {
                navigate("/care");
            },
            icon: CareIcon,
            key: "Extract & Match",
        },
        {
            title: "Finance",
            onClick: () => navigate("/finance"),
            icon: FinanseIcon,
            key: "Finance",
        },
        {
            title: "Search & Highlight",
            onClick: () => navigate("/search_highlight"),
            icon: SearchHighlighterIcon,
            key: "Search and Highlight",
        },
        {
            title: "HR Analysis",
            onClick: () => navigate("/hr_analysis"),
            icon: HRLogo,
            key: "HR Analysis",
        },
        {
            title: "Damage Detection AI",
            onClick: () => navigate("/damage_detection"),
            icon: DamageDetectionIcon,
            key: "Damage Detection auto AI inspection",
        },
        {
            title: "Cybersecurity",
            onClick: () => navigate("/cybersecurity"),
            icon: CyberNavbarIcon,
            key: "Cybersecurity AI",
        },
        // { title: "Chat History", onClick: () => navigate("/chat_history/main"), icon: HistoryIcon },
        {
            title: "Aegon",
            onClick: () => navigate("/aegon"),
            icon: Auegon,
            key: "Aegon",
        },
        {
            title: "Damage Reports",
            onClick: () => navigate("/damageReports"),
            icon: CarCrashSharp,
            key: "Damage Reports",
        },
        {
            title: "TAF",
            onClick: () => navigate("/taf"),
            icon: AccessibilityNewSharp,
            key: "TAF",
        },
        {
            title: "AI Contact Center",
            onClick: () => navigate("/ai_contact_center"),
            icon: AiContactCenter,
            key: "AI Contact Center",
        },
        { title: "Amneal pharma", onClick: () => navigate("/amneal"), icon: EcgIcon, key: "Amneal Batch Report" },
    ];

    const options2 = [{ title: "Calculator", icon: calculator, key: "Calculator" }];

    useEffect(() => {
        const path = window?.location?.pathname;
        if (path?.includes("chat")) {
            setSelected("SensorX Chat");
        }
        if (path === "" || path === "/") {
            setSelected("Home");
            setSelectedPricingCalculator(false);
        }
        if (path?.includes("bullet")) {
            setSelected("SensorX Bullet");
        }
        if (path?.includes("image")) {
            setSelected("AI Images");
        }
        if (path?.includes("compare")) {
            setSelected("Compare Documents");
        }
        if (path?.includes("summarize")) {
            setSelected("Summarize");
        }
        if (path?.includes("data_source/main")) {
            setSelected("Data Sources");
        }
        if (path?.includes("setting")) {
            setSelected("SoftSensorX Settings");
        }
        if (path?.includes("visual")) {
            setSelected("Visual");
        }
        if (path?.includes("pathology")) {
            setSelected("Pathology report");
        }
        if (path?.includes("restaurant_bot")) {
            setSelected("Restaurant Bot");
        }
        // if (path?.includes("anamoly_detection")) {
        //     setSelected("Anamoly Detection");
        // }
        if (path?.includes("claims")) {
            setSelected("Claims");
        }
        if (path?.includes("pricing-calculator")) {
            setSelected("Calculator");
            setSelectedPricingCalculator(true);
        }
        if (path?.includes("ai_contact_center")) {
            setSelected("AI Contact Center");
        }
    }, [window?.location?.pathname]);

    const styles = (obj?: boolean) => {
        if (smd) {
            return {
                main: {
                    position: "absolute",
                    transition: "100ms ease-out",
                    ...(menuCollapse
                        ? { width: "1rem", boxShadow: "", alignItems: "center" }
                        : {
                              height: "-webkit-fill-available",
                              width: "16.5rem",
                              background: "#fff",
                              alignItems: "start",
                          }),
                },
                inner1: {
                    pt: 5,
                    ...(menuCollapse
                        ? { visibility: "hidden" }
                        : { width: "-webkit-fill-available", visibility: "visible" }),
                },
                inner2: {
                    ...(menuCollapse
                        ? { visibility: "hidden" }
                        : { width: "-webkit-fill-available", visibility: "visible" }),
                },
                list: {
                    justifyContent: "flex-start",
                    pl: 1,
                    boxShadow: obj ? "0px 0px 6px 1px #f2f2f2" : "",
                },
            };
        }
    };

    return (
        <Box
            sx={{
                minWidth: "3.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 3,
                py: "1rem",
                zIndex: 2,
                boxShadow: "0.85333px 8px 16.21333px 0px rgba(83, 124, 204, 0.12)",
                ...styles()?.main,
            }}
        >
            {smd && (
                <MuiIconButton
                    onClick={() => setMenuCollapse(!menuCollapse)}
                    sx={{
                        ...(menuCollapse ? {} : { position: "absolute", right: "4px" }),
                    }}
                >
                    {menuCollapse ? <Menu /> : <Close />}
                </MuiIconButton>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: menuCollapse ? "center" : "start",
                    gap: 1,
                    px: 1.5,
                    flex: "1 0 10vh",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {},
                    "&::-webkit-scrollbar-thumb": {
                        boxShadow: "0px 0px 3px 1px #84ceffaa",
                    },
                    ...styles()?.inner1,
                }}
            >
                {!selectedPricingCalculator
                    ? options?.map((elem: any) => (
                          <Box
                              sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  cursor: "pointer",
                                  width: "-webkit-fill-available",
                                  justifyContent: "center",
                                  ...styles(selected === elem?.title)?.list,
                              }}
                              onClick={() => {
                                  setSelected(elem?.title);
                                  elem?.onClick();
                                  setMenuCollapse(true);
                              }}
                          >
                              <Tooltip title={elem?.key} arrow placement="right">
                                  <span>
                                      <IconButton
                                          key={elem?.title}
                                          style={{
                                              height: "2.4rem",
                                              width: "2.4rem",
                                              background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                              boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                              ...(["Search & Highlight"]?.includes(elem?.title)
                                                  ? {
                                                        "& *": {
                                                            stroke: selected === elem?.title ? "#007BFF" : "#484848",
                                                        },
                                                    }
                                                  : {
                                                        "& *": {
                                                            fill: selected === elem?.title ? "#007BFF" : "inherit",
                                                        },
                                                    }),
                                              py: 1,
                                          }}
                                          variant="rounded"
                                      >
                                          <elem.icon />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                              {!menuCollapse && <Typography fontWeight={600}>{elem?.title}</Typography>}
                          </Box>
                      ))
                    : options2?.map((elem: any) => (
                          <Box
                              sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  cursor: "pointer",
                                  width: "-webkit-fill-available",
                                  justifyContent: "center",
                                  ...styles(selected === elem?.title)?.list,
                              }}
                              onClick={() => {
                                  setSelected(elem?.title);
                                  elem?.onClick();
                                  setMenuCollapse(true);
                              }}
                          >
                              <Tooltip title={elem?.key} arrow placement="right">
                                  <span>
                                      <IconButton
                                          key={elem?.title}
                                          style={{
                                              height: "2.4rem",
                                              width: "2.4rem",
                                              background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                              boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                              "& *": {
                                                  fill: selected === elem?.title ? "#007BFF" : "inherit",
                                              },
                                              py: 1,
                                          }}
                                          variant="rounded"
                                      >
                                          <elem.icon />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                              {!menuCollapse && <Typography fontWeight={600}>{elem?.title}</Typography>}
                          </Box>
                      ))}
                {!selectedPricingCalculator && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            cursor: "pointer",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                            ...styles(selected === "Med")?.list,
                        }}
                        onClick={() => {
                            setSelected("Med");
                            navigate("/med");
                            setMenuCollapse(true);
                        }}
                    >
                        <Tooltip title={"Med"} arrow placement="right">
                            <span>
                                <IconButton
                                    key={"Med"}
                                    style={{
                                        height: "2.4rem",
                                        width: "2.4rem",
                                        background: selected === "Med" ? "#E3F4FF" : "#fff",
                                        boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                        "& *": {
                                            stroke: selected === "Med" ? "#007BFF" : "#484848",
                                        },
                                        py: 1,
                                    }}
                                    variant="rounded"
                                >
                                    <MedicalSummarizer />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {!menuCollapse && <Typography fontWeight={600}>{"Med"}</Typography>}
                    </Box>
                )}
            </Box>
            {!selectedPricingCalculator && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: menuCollapse ? "center" : "start",
                        gap: 1,
                        px: 1.5,
                        ...styles()?.inner2,
                    }}
                >
                    {[
                        {
                            title: "Data Sources",
                            onClick: () => navigate("/data_source/main"),
                            icon: DatabaseIcon,
                            key: "Sources",
                        },
                        // {
                        //     title: "SoftSensorX Settings",
                        //     onClick: () => navigate("/setting"),
                        //     icon: SettingIcon,
                        //     key: "Settings",
                        // },
                    ]?.map((elem) => (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                width: "-webkit-fill-available",
                                cursor: "pointer",
                                justifyContent: "center",
                                ...styles(selected === elem?.title)?.list,
                            }}
                            onClick={() => {
                                setSelected(elem?.title);
                                elem?.onClick();
                                setMenuCollapse(true);
                            }}
                        >
                            <Tooltip title={elem?.key} arrow placement="right">
                                <span>
                                    <IconButton
                                        key={elem?.title}
                                        style={{
                                            height: "2.4rem",
                                            width: "2.4rem",
                                            background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                            boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                            "& *": {
                                                fill: selected === elem?.title ? "#007BFF" : "inherit",
                                            },
                                            my: 0.5,
                                        }}
                                        variant="rounded"
                                    >
                                        <elem.icon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {!menuCollapse && <Typography fontWeight={600}>{elem?.title}</Typography>}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default Index;
